@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;

.default {
	font-weight: $normal-base;
	font-size: 1.6rem;
	line-height: 2.2rem;
	padding: 5px 10px 5px;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	color: $white !important;
	background: $deep-green;

	&.fullWidth {
		display: flex;
		width: 100%;
	}

	&.thick {
		padding: 20px;
	}

	&.bulky {
		padding: 10px;
	}

	&.center {
		text-align: center;
	}

	&.soft {
		background: $faded-deep-green;
		color: $deep-green !important;
	}

	&.tiny {
		padding: 2px 6px;
		font-size: 1.4rem;
	}

	@include tabletLarge() {
		padding: 10px;
		white-space: nowrap;

		&.wrap {
			white-space: unset;
		}

		&.slim {
			padding: 5px 8px 5px;
		}
	}
}

.attention {
	background: $purple;

	&.soft {
		background: $softPurple;
		color: $white !important;

		& > * {
			color: $white;
		}
	}
}

.subtle {
	background: $light-grey;
	color: $deep-green !important;
}

.grey {
	background: $faded-deep-green;
	color: $deep-green !important;
}

.offers {
	background: $orange;

	&.soft {
		background: $faded-orange;
		color: $white !important;
	}
}

.error {
	background: $red;

	&.soft {
		background: $faded-red;
		color: $white !important;

		a {
			color: $white;
			text-decoration: underline;
		}
	}
}
