@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;
@use "sass:color";

.fullWidth {
	text-align: center;
	position: relative;

	&.position-relative {
		& .contentWrapper {
			position: relative;
			z-index: 1;
		}
	}

	&.position-static {
		& .contentWrapper {
			position: static;
			z-index: 1;
		}
	}

	&.hasZigZagTop {
		margin-top: 17px;
	}

	h2:not([class*="h2"]) {
		font-size: 3rem;
		line-height: 3.4rem;
		margin-bottom: 0;

		@include desktop() {
			font-size: 4rem;
			line-height: 4.6rem;
		}
	}

	p:last-child {
		margin-bottom: 0;

		@include desktop() {
			margin-bottom: 20px;
		}
	}

	table {
		width: 100%;
		max-width: 600px;
		font-size: 1.6rem;

		td,
		th {
			text-align: center;
		}
	}
}

.stats {
	grid-template-columns: repeat(6, [col-start] 1fr);
	display: grid;
	grid-column-gap: 20px;

	@include desktop() {
		grid-column: 2 / span 10;
	}
}

.stat {
	display: grid;
	gap: 20px;
	margin-top: 20px;
	background-color: color.adjust($off-white, $lightness: -5%);
	padding: 15px;
	grid-column: span 6;

	&:first-child {
		margin-top: 0;
	}

	@include tablet() {
		grid-column: span 3;
		margin-top: 0;
	}

	.statNumber {
		color: $yellow;
		grid-column: 1 / span 1;
	}
	.statDescription {
		text-align: left;
		grid-column: 2 / span 5;
	}
}

.iconPoint {
	width: 85px;
	display: inline-block;
	text-align: center;
	margin: 0px 20px 20px 0;

	@include desktop() {
		margin-top: 10px;
	}

	span {
		display: block;
		font-size: 1.6rem;
		font-weight: $bold-base;
		line-height: 2rem;
	}
}

// BACKGROUND COLOURS
.background-deep-green {
	background-color: $deep-green;
	color: $white;

	p,
	h2,
	h3 {
		color: $white;
	}

	.backgroundImage.zigzagBorder:after {
		background-image: url("/images/zig-zag-deep-green-i.svg");
	}

	.content .stat {
		background-color: color.adjust($deep-green, $lightness: -5%);
	}

	.zigzagBorder {
		background-image: url("/images/zig-zag-deep-green-i.svg");
		position: absolute;
		bottom: 100%;
		right: 0;
		left: 0;
		height: 16px;
		width: 100%;
	}
}

.background-green {
	background-color: $light-green;

	.backgroundImage.zigzagBorder:after {
		background-image: url("/images/zig-zag-green-i.svg");
	}

	.content .stat {
		background-color: color.adjust($light-green, $lightness: -5%);
	}

	.zigzagBorder {
		background-image: url("/images/zig-zag-green-i.svg");
		position: absolute;
		bottom: 100%;
		right: 0;
		left: 0;
		height: 16px;
		width: 100%;
	}
}

.background-purple {
	background-color: $purple;
	color: $white;

	p,
	h2,
	h3 {
		color: $white;
	}

	.backgroundImage.zigzagBorder:after {
		background-image: url("/images/zig-zag-purple-i.svg");
	}

	.zigzagBorder {
		background-image: url("/images/zig-zag-purple-i.svg");
		position: absolute;
		bottom: 100%;
		right: 0;
		left: 0;
		height: 16px;
		width: 100%;
	}

	.content .stat {
		background-color: color.adjust($purple, $lightness: -5%);
	}
}

.mealCounter {
	font-size: 5rem;
	color: $yellow;
	margin: 10px;

	@include tablet() {
		font-size: 5.6rem;
		margin: 0;
	}
}

// SIDEICONS
.sideIcons-right-1,
.sideIcons-right-2,
.sideIcons-left-1,
.sideIcons-left-2,
.sideIcons-left-1-and-right-1,
.sideIcons-xmas-1,
.sideIcons-xmas-2,
.sideIcons-left-2-and-right-2 {
	@include desktop() {
		&:before {
			content: "";
			position: absolute;
			//transform: translateX(50%);
			left: calc(100% - 12vw);
			height: 100%;
			width: 400px;
			background-size: auto 115%;
			background-position: top center;
			background-repeat: no-repeat;
			top: 0;
			z-index: 0;
		}
	}
	@include desktopLarge() {
		&:before {
			left: auto;
			right: -180px;
		}
	}
}

.sideIcons-left-1-and-right-1,
.sideIcons-left-2-and-right-2,
.sideIcons-xmas-2 {
	@include desktop() {
		&:after {
			content: "";
			position: absolute;
			//transform: translateX(50%);
			left: calc(100% - 12vw);
			height: 100%;
			width: 400px;
			background-size: auto 115%;
			background-position: top center;
			background-repeat: no-repeat;
			top: 0;
			z-index: 0;
		}
	}
	@include desktopLarge() {
		&:after {
			left: auto;
			right: -180px;
		}
	}
}

.sideIcons-right-1:before {
	background-image: url("/images/side-icons-right-1.png");
	left: calc(100% - 12vw);
	@include desktopLarge() {
		left: auto;
		right: -170px;
	}
}

.sideIcons-right-2:before {
	background-image: url("/images/side-icons-right-2.png");
	left: calc(100% - 14vw);
	@include desktopLarge() {
		left: auto;
		right: -150px;
	}
}

.sideIcons-left-1:before {
	background-image: url("/images/side-icons-left-1.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -180px;
	}
}

.sideIcons-left-2:before {
	background-image: url("/images/side-icons-left-2.png");
	right: calc(100% - 12vw);
	left: auto;
	@include desktopLarge() {
		right: auto;
		left: -140px;
	}
}

.sideIcons-left-1-and-right-1 {
	&:before {
		background-image: url("/images/side-icons-left-1.png");
		right: calc(100% - 12vw);
		left: auto;
		@include desktopLarge() {
			right: auto;
			left: -180px;
		}
	}
	&:after {
		background-image: url("/images/side-icons-right-1.png");
		left: calc(100% - 12vw);
		@include desktopLarge() {
			left: auto;
			right: -170px;
		}
	}
}

.sideIcons-xmas-2 {
	&:before {
		background-image: url("/images/side-icons-xmas-left-3.png");
		right: calc(100% - 20vw);
		left: auto;
		@include desktopLarge() {
			right: auto;
			left: -100px;
		}
	}
	&:after {
		background-image: url("/images/side-icons-xmas-right-3.png");
		left: calc(100% - 18vw);
		right: auto;
		@include desktopLarge() {
			left: auto;
			right: -140px;
		}
	}
}

.sideIcons-xmas-1 {
	&:before {
		background-image: url("/images/side-icons-xmas-left-1.png");
		right: calc(100% - 12vw);
		left: auto;
		@include desktopLarge() {
			right: auto;
			left: -180px;
		}
	}
	&:after {
		background-image: url("/images/side-icons-xmas-right-1.png");
		left: calc(100% - 12vw);
		@include desktopLarge() {
			left: auto;
			right: -170px;
		}
	}
}

.sideIcons-left-2-and-right-2 {
	&:before {
		background-image: url("/images/side-icons-left-2.png");
		right: calc(100% - 12vw);
		left: auto;
		@include desktopLarge() {
			right: auto;
			left: -140px;
		}
	}
	&:after {
		background-image: url("/images/side-icons-right-2.png");
		left: calc(100% - 14vw);
		@include desktopLarge() {
			left: auto;
			right: -150px;
		}
	}
}
