@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;

.largeControl {
	display: none;
	@include desktop() {
		display: block;
		position: relative;
		grid-column: span 1;
	}

	.largeControlBtn {
		@include desktop() {
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.carouselCont {
	width: 100%;

	&.desktopMaxWidth {
		@include desktop() {
			grid-column: 2 / span 10;
			overflow: hidden;
			position: relative;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 20px;
				background: linear-gradient(
					90deg,
					rgba(234, 229, 220, 1) 0%,
					rgba(234, 229, 220, 0) 100%
				);
				content: "";
				z-index: 10;
			}

			&:after {
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				width: 20px;
				background: linear-gradient(
					270deg,
					rgba(234, 229, 220, 1) 0%,
					rgba(234, 229, 220, 0) 100%
				);
				content: "";
				z-index: 10;
			}
		}
	}
}

.carousel {
	width: 100%;
	margin: 0 auto 0px;
	padding: 0px;
	position: relative;

	@include tablet() {
		padding: 0px;
	}
}

.controls {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 10px;
	gap: 10px;

	.controlButton {
		width: 10px;
		height: 10px;
		background: $light-grey;
		border-radius: 100%;
		cursor: pointer;

		&.active {
			background: $deep-green;
		}
	}
}

.carouselBtn {
	position: absolute;
	left: 0;
	height: 100%;
	width: 40px;
	top: 0;
	z-index: 2;
	content: "";
	background: rgb(2, 0, 36);
	background: linear-gradient(
		270deg,
		rgba(243, 243, 243, 0) 0%,
		rgba(243, 243, 243, 1) 100%
	);
	&:after {
		width: 20px;
		height: 20px;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -10px;
		content: "";
		z-index: 3;
		background-repeat: no-repeat;
		background-image: url(/images/icon-arrow-left.svg);
	}
	&.carouselBtnRight {
		background: linear-gradient(
			90deg,
			rgba(243, 243, 243, 0) 0%,
			rgba(243, 243, 243, 1) 100%
		);

		right: 0;
		left: auto;
		&:after {
			width: 20px;
			right: 0;
			left: auto;
			height: 20px;
			background-repeat: no-repeat;
			background-image: url(/images/icon-arrow-right.svg);
		}
	}
}

.carouselInner {
	position: relative;
	transition: left 0.4s;
	left: 35px;
	width: calc(100% - 35px);
	display: flex;
	column-gap: 10px;

	&.equalHeight {
		align-items: stretch;
	}

	&.notScrollable {
		justify-content: center;
	}

	@include tablet() {
		column-gap: 20px;
	}

	> * {
		flex-shrink: 0;
	}
}
